.footer {
  background-color: var(--primary-color);
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
}

.footer__container {
  width: 80%;
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
}

@media screen and (max-width: 835px) {
  .footer__container {
    gap: 1rem;
  }

  iframe {
    display: none;
  }
}

.footer__link {
  color: var(--secondary-color);
  text-decoration: none;
  font-size: 1.5rem;
  margin: 0 1rem;
  font-size: 1rem;
}

.footer__link:hover {
  color: var(--secondary-color);
  text-decoration: underline;
}
