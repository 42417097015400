.po_items_ho {
  columns: 2;
  column-width: 50%;
  -moz-column-width: 50%;
  -webkit-column-width: 50%;
  column-gap: 2%;
  -moz-column-gap: 2%;
  -webkit-column-gap: 2%;
}

.po_item {
  width: 100%;
  text-align: center;
  /* margin: 0.5rem auto; */
  background: var(--primary-color);
  padding: 6px;
  border: 1px solid var(--secondary-color);
  font-size: 0;
  min-height: 300px;
  height: 90vh;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.po_item img {
  max-width: 100%;
}

.po_item .imageContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.po_item .content {
  /* position: absolute; */
  height: 0;
  width: 0;
  top: 50%;
  left: 50%;
  /* transform: translate(-50%, -50%); */
  color: var(--secondary-color);
  z-index: 1;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  height: 100%;
  width: 100%;
  transition: 0.3s ease-in-out;
  /* opacity: 0; */
}

.po_item .content {
  /* opacity: 0; */
  transition-delay: 1s;
  transition: 0.3s ease;
  font-size: 20px;
}

.content p {
  font-size: 0.8rem;
}

.po_item:hover {
  box-shadow: 0 10px 15px 0 rgba(0, 0, 0, 0.25);
}

/* .po_item:hover .content {
  opacity: 1;
  color: var(--secondary-color);
} */

/* .po_item .content a {
  background-color: var(--primary-color);
  border: none;
  color: var(--secondary-color);
  padding: 12px 24px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 4px 2px;
  cursor: pointer;
  border-radius: 8px;
  transition: background-color 0.3s;
} */
/* .po_item .content a:hover {
  text-decoration: none;
  outline: red solid 3px;
} */

.spec_container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: left;
  margin: 0 auto;
  width: 100%;
  max-width: 800px;
  padding: 0 1rem;
}

.ac_btn {
  margin-bottom: 15px;
}

.items {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  gap: 30px;
  margin: 0 auto;
  /* margin-bottom: 100px; */
}

@media (min-width: 991.98px) {
  .items .po_item {
    max-width: 40%;
  }
}
