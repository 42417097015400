section {
  flex: 1 0 auto;
  position: relative;
  width: 100%;
  -webkit-transition: all 0.5s ease-in;
  -o-transition: all 0.5s ease-in;
  transition: all 0.5s ease-in;
}

.who_am_I {
  font-family: Cinzel;
}

.has-first-color {
  color: var(--primary-color);
}

.btn-portfolio {
  background: var(--primary-color);
  border-radius: 0;
}

.btn-portfolio a {
  color: #000;
  text-decoration: none;
}

.react-slideshow-container .default-nav{
  border-radius: 0 !important;
  padding: 0 !important;
}

.btn-about a {
  color: var(--text-color);
  text-decoration: none;
}

.intro_sec {
  height: calc(100vh - 60px);
  min-height: 700px;
  /* height: 100vh; */
  margin-top: -60px;
}

@media (max-width: 991.98px) {
  .intro_sec {
    display: block;
    height: auto !important;
  }
}

.intro_sec .text,
.intro_sec .h_bg-image {
  width: 50%;
}

@media (max-width: 991.98px) {
  .intro_sec .text,
  .intro_sec .h_bg-image {
    width: 100%;
  }
}

.intro_sec .intro {
  max-width: 450px;
  margin: 0 auto;
}

@media (max-width: 991.98px) {
  .intro_sec .intro {
    max-width: 700px;
    padding-left: 20px;
    padding-right: 20px;
  }
}

.intro_sec .intro .feature .wrap-icon {
  background: 0 0 !important;
  width: auto;
  height: auto;
  margin-bottom: 0;
}

.intro_sec .intro .feature .wrap-icon svg {
  color: #5cccc9;
}

.intro_sec .text h1 {
  font-size: 30px;
  margin-bottom: 50px;
  font-weight: 700;
}

.intro_sec .text h3 {
  font-size: 16px;
  font-weight: 700;
}

.intro_sec .h_bg-image {
  background-size: cover;
  background-position: center;
  min-height: calc(100vh - 60px);
  position: relative;
}

.intro_sec .h_bg-image .block {
  bottom: 50vh;
  max-width: 220px;
  padding: 30px;
  left: -110px;
  position: absolute;
  width: 250px;
  height: 220px;
  border-radius: 100%;
  /* mix-blend-mode: multiply; */
  background-color: #2f514e;
  z-index: 9999999999;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}

@media (max-width: 991.98px) {
  .intro_sec .h_bg-image {
    height: 600px;
    min-height: 75vh;
    margin-bottom: 30px;
  }
  .intro_sec .h_bg-image .block {
    top: unset;
    bottom: 0;
    width: 100%;
    margin: 0;
    left: 50%;
    transform: translate(-50%, 50%);
  }
}

.intro_sec .h_bg-image .block .wrap-icon {
  font-size: 40px;
  color: var(--text-color);
  margin-bottom: 10px;
  display: block;
}

.intro_sec .h_bg-image .block h3 {
  font-size: 14px;
  color: var(--text-color);
  letter-spacing: 0.1rem;
  font-weight: 700;
  margin-bottom: 15px;
}

.intro_btn-action {
  display: flex;
  gap: 10px;
}

.intro_sec .h_bg-image .block p {
  font-size: 14px;
  line-height: 1.3;
  color: var(--text-color);
}

.intro_sec .h_bg-image {
  filter: saturate(0.5);
}

.tile_container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  width: 100vw;
  flex-direction: column;
  padding-left: 5%;
  gap: 20px;
}

.tile {
  border-radius: 10px;
  margin: auto;
  display: flex;
  justify-content: left;
  flex-direction: column;
  width: calc(100% - 100px);
}

.tile p {
  color: var(--text-color);
  width: 100%;
  /* max-width: 95vw; */
  font-size: 1rem;
  font-family: Raleway;
}

.tile .tile_content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 10px;
}

.tile_content h3 {
  font-size: 1.5rem;
  font-family: Raleway;
  color: red;
  margin-bottom: 10px;
  padding-left: 2rem;
}

.tile_content_container {
  font-size: 1.5rem;
  font-family: Raleway;
  color: var(--text-color);
  margin-bottom: 10px;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin-top: 2%;
  gap: 20px;
}

.tile_content_container p {
  background-color: var(--secondary-color);
  color: var(--primary-color);
  font-size: 1rem;
  font-family: Raleway;
  max-width: 400px;
  /* background-color: #f7f7f7; */
  padding: 20px;
  transition: transform 0.3s ease-in-out;
}

.tile_content_container p:hover {
  outline: var(--secondary-color) solid 2px;
  transform: scale(1.1);
}

.image_display {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  flex-direction: row;
  gap: 30px;
  max-width: 90%;
}

.image_display .image_block {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 175px;
  height: auto;
  border-radius: 10px;
}

.image_block img {
  height: 100%;
  width: 100%;
  border-radius: 10px;
}

.logos {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  margin: 100px auto 100px;
  flex-direction: column;
  gap: 30px;
  width: 100vw;
  background-color: var(--secondary-color-2);
  min-height: 200px;
  padding-bottom: 5%;
  padding-top: 2%;
}

.logos .logo_container {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  height: auto;
}

.logo svg {
  width: 75px;
  height: 75x;
}

.drawing_2_part {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  flex-direction: row;
  gap: 30px;
  max-width: 90%;
}

.drawing_2_part p {
  margin-top: 20px;
  margin-bottom: 5%;
}

.drawing_img {
  width: 90%;
  box-shadow: #0000009b 0px 0px 5px 0px;
}

.rapid {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  flex-direction: row;
  gap: 30px;
}

.article_item {
  max-width: 90%;
}

@media (max-width: 1350px) {
  #article-1 {
    flex-wrap: wrap;
  }
}

#article-1-img {
  max-width: 50%;
  max-height: 400px;
}

.service_icon {
  width: 100%;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
}

.service_icon img {
  max-width: 100%;
}

.service_box {
  display: flex;
  margin: 0 auto;
  gap: 30px;
  /* justify-content: center; */
  /* align-items: center; */
  flex-direction: column-reverse;
}

@media screen and (min-width: 1080px) {
  .service_box {
    /* flex-direction: row-reverse; */
  }

  .service_box img{
    height: 700px;
    width: 70%;
    object-fit: cover;
  }
}

.img_cont {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.service_content {
  width: 100%;
  justify-content: center;
  align-items: center;
  font-size: 1rem;
}

.service_content p {
  max-width: 50vw;
}
