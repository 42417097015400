.items_mats {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
  padding: 0;
  list-style: none;
  text-align: center;
  flex-direction: column;
  width: 100%;
}

.item {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 15%;
  width: 100%;
  padding: 1%;
  border-radius: 5px;
  color: var(--text-color);
  gap: 5%;
}

@media (min-width: 991.98px) {
  /* Alternate the flex direction for each item */
  .item:nth-child(even) {
    flex-direction: row-reverse;
  }

  .item:nth-child(odd) {
    flex-direction: row;
  }
}

.item:hover {
  transform: scale(1.001);
}

.item img {
  object-fit: cover;
  border-radius: 5px;
  pointer-events: none;
  width: 80%;
}

.item .expand {
  display: flex;
  flex-direction: column;
  text-align: left;
}

.item .expand .type_desc {
  display: block;
  max-width: 100%;
  color: var(--text-color);
    font-size: 0.8em;
}

.item .expand:hover .type_desc {
  display: flex;
  transition: 0.5s ease all 0.5s;
}

.mat_type {
  font-size: 1.5em;
  font-weight: bold;
  color: var(--text-color-3);
}

.item-content {
  display: flex;
  flex-direction: column;
  margin-left: 5%;
  width: 100%;
}

.mat_desc {
  width: 80vw;

}