:root {
  --bg-color: #0c0c0c;
  --primary-color: #0d0d0d;
  --secondary-color: #fff;
  --secondary-color-2: #7f7f7f;
  --text-color: #fff;
  --text-color-2: #fff;
  --text-color-3: rgb(204, 0, 0);
  --overlay-color: rgba(164, 164, 164, 0.63);
}

[data-theme="light"] {
  --bg-color: #ffffff;
  --primary-color: #ffffff;
  --secondary-color: #000;
  --text-color: #000;
  --text-color-2: #000;
  --text-color-3: rgb(204, 0, 0);
  --text-color-secondary: #fff;
  --overlay-color: rgb(255 255 255 / 70%);
}

html,
body {
  height: 100%;
}

body {
  margin: 0;
  height: 100%;
  overflow-x: hidden;
  overflow-y: visible;
  background-color: var(--bg-color);
  color: var(--text-color);
  font-family: "Raleway", sans-serif;
  font-size: 1.1rem;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  padding-top: 60px;
  border-left: 10px solid var(--primary-color);
  border-right: 10px solid var(--primary-color);
}

ul {
  list-style: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: Marcellus;
}

a,
a:hover {
  color: var(--text-color);
}

p {
  word-break: break-word;
}

.ovhidden {
  overflow: hidden;
}

.text_2,
.text_2:hover {
  color: var(--text-color-2);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.cursor__dot div {
  z-index: 999999 !important;
}

.cursor__dot div:last-child {
  background-color: var(--text-color-3) !important;
}

.cursor__dot div:first-child {
  filter: invert(1);
  background-color: var(--overlay-color) !important;
}

.color_pr {
  color: var(--primary-color) !important;
}

.color_sec {
  color: var(--secondary-color);
}

.ac_btn {
  padding: 4px 19px;
  color: var(--secondary-color);
  position: relative;
  border: var(--secondary-color) 2px solid;
  overflow: hidden;
  transition: all 0.6s cubic-bezier(0.55, 0, 0.1, 1);
  cursor: pointer;
  border-radius: 0;
}

.ac_btn a {
  text-decoration: none;
}

.ac_btn:hover {
  box-shadow: 8px 8px 0px var(--text-color), -8px -8px 0px var(--text-color);
}

.ac_btn:hover .one {
  opacity: 1;
  transform: translate3d(0px, 0px, 0px);
}

.ac_btn:hover .two {
  transform: translate3d(0px, 0px, 0px);
}

.ac_btn:hover .three {
  transform: translate3d(0px, 0px, 0px);
}

.ac_btn:hover .four {
  transform: translate3d(0px, 0px, 0px);
}

.ac_btn .ring {
  width: 100%;
  height: 100%;
  position: absolute;
  background: transparent;
  top: 0;
  left: 0;
  transform: translate3d(0px, 90px, 0px);
}

.ac_btn .one {
  background-color: #000;
  transition: all 0.3s cubic-bezier(0.55, 0, 0.1, 1);
  z-index: -3;
  z-index: -4;
}

.ac_btn .two {
  background-color: var(--primary-color);
  transition: all 0.5s cubic-bezier(0.55, 0, 0.1, 1);
  z-index: -3;
}

.ac_btn .three {
  background-color: var(--secondary-color);
  z-index: -2;
  transition: all 0.7s cubic-bezier(0.55, 0, 0.1, 1);
  z-index: -3;
}

#button_p {
  background: var(--secondary-color);
  color: var(--primary-color);
}

#button_h:hover {
  color: var(--primary-color);
}

.flex {
  display: flex;
}

.flex__center {
  justify-content: center;
}

.flex__column {
  flex-direction: column;
}

.flex__row {
  flex-direction: row;
}

.flex__wrap {
  flex-wrap: wrap;
}

@media (max-width: 768px) {
  .body {
    font-family: 0.8rem;
  }
}